<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('SIGNER_INFO.TITLE')}}</a></li>
    </ul>
    <div class="page">

      <div class="page-title">
        <h1>{{$t('SIGNER_INFO.TITLE')}}</h1>
        <p>{{$t('SIGNER_INFO.DESCRIPTION')}}</p>
      </div>

      <div class="card biz-primary mt-4">
        <div class="card-header">
          {{$t('SIGNER_INFO.HEADER_SIGNER')}}
          <div class="control">
            <router-link to="/supplier/manage/signer-info/edit">{{$t('COMMON.EDIT')}}</router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label">{{$t('SIGNER_INFO.SIGNER')}}</label>
                <div class="biz-form-body" v-if="signer">
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('SIGNER_INFO.POSITION')}}:</span>{{signer.jobTitle || $t('COMMON.NOT_REGISTERED')}}
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.NAME')}}:</span><span>{{signer.lastName + signer.firstName || $t('COMMON.NOT_REGISTERED')}}</span>
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.NAME_KANA')}}:</span><span>{{signer.lastNameKana + signer.firstNameKana || $t('COMMON.NOT_REGISTERED')}}</span>
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.NAME_EN')}}:</span><span>{{signer.lastNameEn +' '+ signer.firstNameEn || $t('COMMON.NOT_REGISTERED')}}</span>
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.PHONENUMBER')}}:</span>{{signer.mobilePhone || $t('COMMON.NOT_REGISTERED')}}
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.EMAIL_ADDRESS')}}:</span>{{signer.email || $t('COMMON.NOT_REGISTERED')}}
                  </p>
                </div>
                <div class="biz-form-body" v-if="!signer">
                  <p class="biz-offset">
                    {{$t('COMMON.NOT_SET')}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label">{{$t('SIGNER_INFO.SUBSTITUTE')}}</label>
                <div class="biz-form-body"  v-if="substitute">
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('SIGNER_INFO.POSITION')}}:</span>{{substitute.jobTitle || $t('COMMON.NOT_REGISTERED')}}
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.NAME')}}:</span><span>{{substitute.lastName + substitute.firstName || $t('COMMON.NOT_REGISTERED')}}</span>
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.NAME_KANA')}}:</span><span>{{substitute.lastNameKana + substitute.firstNameKana || $t('COMMON.NOT_REGISTERED')}}</span>
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.NAME_EN')}}:</span><span>{{substitute.lastNameEn +' '+ substitute.firstNameEn || $t('COMMON.NOT_REGISTERED')}}</span>
                  </p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.PHONENUMBER')}}:</span>{{substitute.mobilePhone || $t('COMMON.NOT_REGISTERED')}}</p>
                  <p class="biz-offset">
                    <span class="simple-label">{{$t('COMMON.EMAIL_ADDRESS')}}:</span>{{substitute.email || $t('COMMON.NOT_REGISTERED')}}</p>
                </div>
                <div class="biz-form-body" v-if="!substitute">
                  <p class="biz-offset">
                    {{$t('COMMON.NOT_SET')}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <j-button variant="light" to="/supplier/manage">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          {{$t('COMMON.GO_BACK')}}
        </j-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .simple-label{
    min-width: 150px;
    display:inline-block;
  }
</style>
<script>
  import UserInfo from '@/model/UserInfo.js';
  
  export default {
    components: {  },
    data: () => ({
      signer: {},
      substitute: {}
    }),
    created: function(){
      UserInfo.getSigner(this.$store.state.user.companyCode).then(result => {
        this.signer = result.data.signer;
        this.substitute = result.data.substitute;
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    }
  }
</script>